const http = require('@/middleware/axios').default

export default {
  doGetAll () {
    return new Promise((resolve, reject) => {
      http.get('/system/controls').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGetByKey (controlKey) {
    return new Promise((resolve, reject) => {
      http.get('/system/control', {
        params: {
          controlKey
        }
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doUpdateControl (controlKey, controlValue) {
    return new Promise((resolve, reject) => {
      http.put('/system/control', controlValue, {
        params: {
          controlKey
        }
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
