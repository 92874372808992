const http = require('@/middleware/axios').default

export default {
  doUploadImage (directory, name, image, onProgress) {
    return new Promise((resolve, reject) => {
      const uploadForm = new FormData()
      uploadForm.append('directory', directory)
      uploadForm.append('filename', name)
      uploadForm.append('image', image)

      http.post('/media/upload/image', uploadForm, {
        onUploadProgress: onProgress
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
