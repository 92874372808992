const http = require('@/middleware/axios').default

export default {
  doNotifyCompany (name, phoneNumber, email, message) {
    return new Promise((resolve, reject) => {
      http.post('/contact/company', {
        name: name,
        phoneNumber: phoneNumber,
        email: email,
        message: message
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
