const routes = {
  path: '/auth',
  component: () => import('../views/auth/index'),
  redirect: '/auth/signin',
  meta: {
    showNavigation: false,
    showFooter: false,
    requiresAuth: false
  },
  children: [
    {
      name: 'signin',
      path: 'signin',
      component: () => import('../views/auth/SignIn'),
      meta: {
        showNavigation: false,
        showFooter: false,
        requiresAuth: false
      }
    },
    {
      name: 'auth-forgot',
      path: 'help',
      component: () => import('../views/auth/forgotAuth'),
      meta: {
        showNavigation: false,
        showFooter: false,
        requiresAuth: false
      }
    }
  ]
}

export default routes
