const http = require('@/middleware/axios').default

export default {
  auth: {
    doRequestVerificationCode (phoneNumber) {
      return new Promise((resolve, reject) => {
        http.get('/user/auth/otp', {
          params: {
            phoneNumber: phoneNumber
          }
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doAuthenticateOTP (phoneNumber, verificationCode) {
      return new Promise((resolve, reject) => {
        http.post('/user/auth/otp', {
          phoneNumber: phoneNumber,
          verificationCode: verificationCode
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doAuthenticateEmail (email, password) {
      return new Promise((resolve, reject) => {
        http.post('/user/auth/email', {
          email: email,
          password: password
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  profile: {
    doRead () {
      return new Promise((resolve, reject) => {
        http.get('/user/profile').then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  doGetAll () {
    return new Promise((resolve, reject) => {
      http.get('/user/all').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doReadById (id) {
    return new Promise((resolve, reject) => {
      http.get(`/user/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doCreate (firstName, lastName, email, phoneNumber, password, roleKey) {
    return new Promise((resolve, reject) => {
      http.post('/user', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        password: password,
        roleKey: roleKey
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doResetPassword (userId) {
    return new Promise((resolve, reject) => {
      http.post('/user/reset', {
        id: userId
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doSetPassword (password) {
    return new Promise((resolve, reject) => {
      http.post('/user/password', {
        password: password
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
