const routes = {
  name: 'checkout',
  path: '/checkout',
  component: () => import('../views/checkout'),
  redirect: { name: 'checkout-cart' },
  meta: {
    requiresAuth: false
  },
  children: [
    {
      name: 'checkout-cart',
      path: 'cart',
      component: () => import('../views/checkout/cart'),
      meta: {
        icon: '',
        showOnNavbar: false,
        showNavbar: true
      }
    }
  ]
}

export default routes
