import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faUpRightFromSquare, faArrowRightToBracket, faArrowLeft,
  faArrowRight, faArrowPointer, faFire, faAnchor, faHouse,
  faUserGraduate, faPlane, faPeopleGroup, faCircleInfo,
  faCartShopping, faPhone, faIdBadge, faEnvelope,
  faExclamationTriangle, faTimesCircle, faExclamation,
  faGlobe, faPlaneDeparture, faArrowsLeftRight, faChalkboardUser,
  faShip, faMagnifyingGlass, faChevronLeft, faChevronRight,
  faCartPlus, faGauge, faWandMagicSparkles, faPersonSwimming,
  faPersonDrowning, faGear, faArrowUp, faArrowDown, faCaretDown,
  faCheckSquare, faImage, faTrash, faSquarePlus, faCalendarDays,
  faDollarSign, faClock, faSortUp, faMoneyBill, faLocationPin,
  faPlus, faChevronDown, faTimes, faCopy, faGears, faCheck,
  faHandPointUp, faX, faWater, faMicroscope, faRuler, faBrain, faComments, faLifeRing
} from '@fortawesome/free-solid-svg-icons'
import { faCcStripe, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally

// library.add(fas)
library.add([
  faUpRightFromSquare, faArrowRightToBracket, faArrowLeft,
  faArrowRight, faArrowPointer, faFire, faAnchor, faHouse,
  faUserGraduate, faPlane, faPeopleGroup, faCircleInfo,
  faCartShopping, faPhone, faIdBadge, faEnvelope,
  faExclamationTriangle, faTimesCircle, faExclamation,
  faGlobe, faPlaneDeparture, faArrowsLeftRight, faChalkboardUser,
  faShip, faMagnifyingGlass, faChevronLeft, faChevronRight,
  faCartPlus, faGauge, faWandMagicSparkles, faPersonSwimming,
  faPersonDrowning, faGear, faArrowUp, faArrowDown, faCaretDown,
  faCheckSquare, faImage, faTrash, faSquarePlus, faCalendarDays,
  faDollarSign, faClock, faSortUp, faMoneyBill, faLocationPin,
  faPlus, faChevronDown, faTimes, faCopy, faGears, faCheck, faHandPointUp,
  faX, faWater, faMicroscope, faRuler, faBrain, faComments, faLifeRing
])
library.add(faFacebook) // Include needed icons
library.add(faInstagram) // Include needed icons
library.add(faCcStripe)

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      dark: {
        primary: '#156B96',
        secondary: '#87BADD',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#2ecc71',
        warning: '#e67e22'
      },
      light: {
        primary: '#156B96',
        secondary: '#87BADD',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#2ecc71',
        warning: '#e67e22'
      }
    }
  },
  icons: {
    iconfont: 'faSvg'
  }
})
