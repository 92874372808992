const http = require('@/middleware/axios').default

export default {
  doReadPublicly (uuid) {
    return new Promise((resolve, reject) => {
      http.get('/transaction/public/byUuid', {
        params: {
          uuid: uuid
        }
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doReadAllProcessed () {
    return new Promise((resolve, reject) => {
      http.get('/transaction/processed').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doReadInternal (uuid) {
    return new Promise((resolve, reject) => {
      http.get('/transaction/byUuid', {
        params: {
          uuid: uuid
        }
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doFulfillProduct (transactionUuid, product, value) {
    return new Promise((resolve, reject) => {
      http.put('/transaction/fulfill/product/', {
        uuid: transactionUuid,
        product: product,
        fulfilled: value
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doFulfillContact (transactionUuid, value) {
    return new Promise((resolve, reject) => {
      http.put('/transaction/fulfill/contact/', {
        uuid: transactionUuid,
        fulfilled: value
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doFulfillNoteAddition (transactionUuid, note) {
    return new Promise((resolve, reject) => {
      http.put('/transaction/fulfill/note/', {
        uuid: transactionUuid,
        note: note
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doProcessRefund (transactionUuid, cents, reason) {
    return new Promise((resolve, reject) => {
      http.post('/transaction/refund/', {
        uuid: transactionUuid,
        cents: cents,
        reason: reason
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doEmail (transactionUuid) {
    return new Promise((resolve, reject) => {
      http.post('/transaction/email/', {
        uuid: transactionUuid
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
