<template>
  <section>
    <div>
      <v-snackbar v-model="cookies">
        This website uses cookies.
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="doShowPolicy">
            Learn More
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </section>
</template>

<script>
export default {
  name: 'cookiesReminder',
  data: () => ({
    cookies: false
  }),
  methods: {
    doNotify () {
      this.cookies = true
    },
    doShowPolicy () {
      this.$router.push({ name: 'policy', params: { scrollTo: 'privacy' } })
    }
  }
}
</script>

<style scoped>

</style>
