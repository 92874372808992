const http = require('@/middleware/axios').default

export default {
  doCreate (name, subtitle, content, images, isActive = true) {
    return new Promise((resolve, reject) => {
      const trip = {
        name: name,
        subtitle: subtitle,
        content: content,
        images: images,
        isActive: isActive
      }

      http.post('/trip', trip).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGetAll () {
    return new Promise((resolve, reject) => {
      http.get('/trip').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGetAllActive () {
    return new Promise((resolve, reject) => {
      http.get('/trip/active').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGetClosest () {
    return new Promise((resolve, reject) => {
      http.get('/trip/closest').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGetById (id) {
    return new Promise((resolve, reject) => {
      http.get(`/trip/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doUpdate (id, name, subtitle, content, images) {
    return new Promise((resolve, reject) => {
      const trip = {
        name: name,
        subtitle: subtitle,
        content: content,
        images: images
      }

      http.put(`/trip/${id}`, trip).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doDeactivate (id) {
    return new Promise((resolve, reject) => {
      http.post(`/trip/deactivate/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doActivate (id) {
    return new Promise((resolve, reject) => {
      http.post(`/trip/activate/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doFill (id) {
    return new Promise((resolve, reject) => {
      http.post(`/trip/fill/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doUnfill (id) {
    return new Promise((resolve, reject) => {
      http.post(`/trip/unfill/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doDestroy (id) {
    return new Promise((resolve, reject) => {
      http.delete(`/trip/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  date: {
    doCreate (tripId, start, end) {
      return new Promise((resolve, reject) => {
        const tripDate = {
          tripId: tripId,
          start: start,
          end: end
        }

        http.post('/trip/date', tripDate).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doGetById (id) {
      return new Promise((resolve, reject) => {
        http.get(`/trip/date/${id}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doUpdate (id, start, end) {
      return new Promise((resolve, reject) => {
        const tripDate = {
          start: start,
          end: end
        }

        http.put(`/trip/date/${id}`, tripDate).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doDestroy (id) {
      return new Promise((resolve, reject) => {
        http.delete(`/trip/date/${id}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  product: {
    doCreate (tripDateId, tripProductCategoryId, name, description, cents, isRequired) {
      return new Promise((resolve, reject) => {
        const tripProduct = {
          tripDateId: tripDateId,
          tripProductCategoryId: tripProductCategoryId,
          name: name,
          description: description,
          cents: cents,
          isRequired: isRequired
        }

        http.post('/trip/product', tripProduct).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doGetByDateId (tripDateId) {
      return new Promise((resolve, reject) => {
        http.get(`/trip/product/byDateId/${tripDateId}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doGetById (id) {
      return new Promise((resolve, reject) => {
        http.get(`/trip/product/${id}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doUpdate (id, tripProductCategoryId, name, description, cents, isRequired) {
      return new Promise((resolve, reject) => {
        const trip = {
          name: name,
          tripProductCategoryId: tripProductCategoryId,
          description: description,
          cents: cents,
          isRequired: isRequired
        }

        http.put(`/trip/product/${id}`, trip).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doDestroy (id) {
      return new Promise((resolve, reject) => {
        http.delete(`/trip/product/${id}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    category: {
      doGetAll () {
        return new Promise((resolve, reject) => {
          http.get('/trip/product/category/all').then(data => {
            resolve(data.data)
          }).catch(error => {
            reject(error)
          })
        })
      }
    }
  }
}
