const routes = {
  name: 'order',
  path: '/order',
  component: () => import('../views/order'),
  redirect: { name: 'checkout-cart' },
  meta: {
    requiresAuth: false
  },
  children: [
    {
      name: 'order-success',
      path: 'success',
      component: () => import('../views/order/success'),
      meta: {
        icon: '',
        showOnNavbar: false,
        showNavbar: true
      }
    }
  ]
}

export default routes
