const http = require('@/middleware/axios').default

export default {
  doCreatePaymentIntent (products) {
    return new Promise((resolve, reject) => {
      http.post('/checkout/paymentIntent', {
        products: products
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doProcessTransaction (customer, billingAddress, transaction) {
    return new Promise((resolve, reject) => {
      http.post('/checkout/processTransaction', {
        customer: customer,
        billingAddress: billingAddress,
        transaction: transaction
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doCancelTransaction (transactionUuid) {
    return new Promise((resolve, reject) => {
      http.delete('/checkout/paymentIntent', {
        params: {
          transactionUuid: transactionUuid
        }
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
