<template>
  <div>
    <svg viewBox="0 0 140 100" :width="width" :height="height" id="unixityLogo">
      <path id="logoBoxPath" :class="show ? 'startAnimationlogoBoxPath' : ''" d="m 83 22 L 83 8 L 8 8 L 8 83 L 83 83 L 83 67" stroke-width="2" fill="none"/>
      <path id="logoInfinityPath" :class="show ? 'startAnimationlogoInfinityPath' : ''" d="M 81 44 L 79 41 C 78 39 78 39 78 38 C 78 37 78 33 83 33 C 87 33 88 37 88 38 C 88 39 88 39 87 41 L 79 52 C 78 54 78 54 78 55 C 78 56 79 59 83 59 C 87 59 88 56 88 55 C 88 54 88 54 87 52 L 85 49" stroke-width="4.5" fill="none"></path>
      <path id="logoXPath" :class="show ? 'startAnimationlogoXPath' : ''" d="M 81 44 L 79 41 C 78 39 78 39 78 38 M 88 38 C 88 39 88 39 87 41 L 79 52 C 78 54 78 54 78 55 M 88 55 C 88 54 88 54 87 52 L 85 49" stroke-width="4.5" fill="none"></path>
      <text id="logoText" :class="show ? 'startAnimationLogoText' : ''" x="27" y="55" font-size="2em">Uni</text>
      <text id="logoText" :class="show ? 'startAnimationLogoText' : ''" x="91" y="55" font-size="2em">ity</text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'unixityLogoText',
  props: {
    width: Number,
    height: Number
  },
  data: () => ({
    show: false
  }),
  methods: {
    checkVisible () {
      const rect = document.getElementById('unixityLogo').getBoundingClientRect()
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
    },
    doDetectInView () {
      this.show = this.checkVisible()
    }
  },
  created () {
    window.addEventListener('scroll', this.doDetectInView)
  },
  mounted () {
    this.show = this.checkVisible()
  },
  unmounted () {
    window.removeEventListener('scroll', this.doDetectInView)
  }
}
</script>

<style scoped>
#logoBoxPath {
  stroke: white;
  stroke-dasharray: 255;
  stroke-dashoffset: 255;
}

#logoInfinityPath {
  stroke: white;
  stroke-dasharray: 64;
  stroke-dashoffset: 192;
}

#logoXPath {
  stroke: white;
  opacity: 0;
}

#logoText {
  fill: white;
  font-weight: bolder;
  opacity: 0;
}

.startAnimationLogoText {
  animation: logoOpacityFill 0.3s linear forwards;
}

.startAnimationlogoXPath {
  animation: logoOpacityFill 0s linear forwards;
  animation-delay: 0.7s;
}

.startAnimationlogoInfinityPath {
  animation: logoInfinityPathFillAndEmpty 1.4s linear forwards;
}

.startAnimationlogoBoxPath {
  animation: logoBoxPathFill 0.7s linear forwards;
}

@keyframes logoBoxPathFill {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes logoInfinityPathFillAndEmpty {
  to {
    stroke-dashoffset: 64;
  }
}

@keyframes logoOpacityFill {
  to {
    opacity: 1;
  }
}
</style>
