const http = require('@/middleware/axios').default

export default {
  doCreate (firstName, lastName, email, title, about, image, isPublic = false) {
    return new Promise((resolve, reject) => {
      const teamMember = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        title: title,
        about: about,
        image: image,
        isPublic: isPublic
      }

      http.post('/team/', teamMember).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGetAll () {
    return new Promise((resolve, reject) => {
      http.get('/team/all').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGetAllPublic () {
    return new Promise((resolve, reject) => {
      http.get('/team/all/public').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doRead (id) {
    return new Promise((resolve, reject) => {
      http.get(`/team/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doUpdate (id, firstName, lastName, email, title, about, image, isPublic = false) {
    return new Promise((resolve, reject) => {
      const teamMember = {
        firstName: firstName,
        lastName: lastName,
        about: about,
        email: email,
        title: title,
        image: image,
        isPublic: isPublic
      }

      http.put(`/team/${id}`, teamMember).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doMakePublic (id) {
    return new Promise((resolve, reject) => {
      http.post(`/team/activate/public/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doMakePrivate (id) {
    return new Promise((resolve, reject) => {
      http.post(`/team/activate/private/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doDestroy (id) {
    return new Promise((resolve, reject) => {
      http.delete(`/team/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
