const http = require('@/middleware/axios').default

export default {
  doGenerate () {
    return new Promise((resolve, reject) => {
      http.get('/session').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGather (uuid) {
    return new Promise((resolve, reject) => {
      http.get('/session/track', {
        params: {
          uuid: uuid
        }
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doRoute (uuid, route) {
    return new Promise((resolve, reject) => {
      http.get('/session/route', {
        params: {
          uuid: uuid,
          route: route
        }
      }).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
